import type { GetStaticPaths } from 'next'
import { BuilderComponent, builder, useIsPreviewing } from '@builder.io/react'
import nextContentWrapper from 'cms/nextContentWrapper'
import ErrorNotFoundPage from '~/components/Error/NotFound/Page'
import Metadata from '~/components/Metadata'
import { InferNextPage } from '~/types'
import 'cms/builder/registry'
import GlobalStyles from '~/theme/GlobalStyles'
import {useState, useEffect} from 'react'

builder.init(process.env.NEXT_PUBLIC_BUILDER_API_KEY)

const pageModels = (
  process.env.PROPERTYLENS_SUPPORTED_PAGE_MODELS ?? 'page'
).split(',')

export const getStaticProps = nextContentWrapper.getStaticProps(
  async ({ params }) => {
    const urlPath = '/' + ((params?.page as string[])?.join('/') || '')

    const pageModelsData = await Promise.all(
      pageModels.map(async (pageModel) => {
        try {
          const page = await builder
            .get(pageModel, {
              userAttributes: {
                urlPath: urlPath,
              },
            })
            .toPromise()

          if (page) {
            return { pageModel, page }
          }
        } catch (error) {
          console.error(error)
        }
        return null
      })
    )

    const fetchedPage = pageModelsData.find((result) => result !== null)
    const fetchedPageModel = fetchedPage?.pageModel || null
    const page = fetchedPage?.page || null

    return {
      props: {
        page,
        pageModel: fetchedPageModel,
      },
      revalidate: 5,
    }
  }
)

const generateNestedPaths = (
  path: string,
  validUrls: Set<string>
): string[] => {
  const segments = path.split('/').filter(Boolean)
  const nestedPaths: string[] = []

  for (let i = 0; i < segments.length; i++) {
    const nestedPath = '/' + segments.slice(0, i + 1).join('/')
    if (validUrls.has(nestedPath)) {
      nestedPaths.push(nestedPath)
    } else {
      console.log(`Excluded ${nestedPath} - not in valid URLs`)
    }
  }

  return nestedPaths
}

export const getStaticPaths: GetStaticPaths = async () => {
  const allPages = await builder.getAll('page', {
    fields: 'data.url',
    options: { cachebust: true, noTargeting: true },
  })
  const validUrls = new Set(
    allPages.map((page) => page.data?.url).filter(Boolean)
  )

  const paths = allPages.map((page) => `${page.data?.url}`)
  const nestedPaths = paths.flatMap((path) =>
    generateNestedPaths(path, validUrls)
  )

  const formattedPaths = [...paths, ...nestedPaths].map((path) => ({
    params: { page: path.split('/').filter(Boolean) },
  }))

  return {
    paths: formattedPaths,
    fallback: 'blocking',
  }
}

const Page: InferNextPage<typeof getStaticProps> = ({ page, pageModel }) => {
  const isPreviewing = useIsPreviewing()
  const env = process.env.NODE_ENV || 'development'
  const [hydrated, setHydrated] = useState(env === 'production')

  useEffect(() => {
    // this forces a rerender
    setHydrated(true)
  }, [])

  if(!hydrated) {
    // this returns null on first render, so the client and server match
    return null
  }
  if (!page && !isPreviewing) {
    return <ErrorNotFoundPage />
  }

  return (
    <>
      <Metadata
        title={page?.data?.title}
        description={page?.data?.description}
        canonical={page?.data?.url}
        pageMetaData={page?.data}
      />
      <GlobalStyles />
      <BuilderComponent
        model={pageModel ?? 'page'}
        content={page ?? undefined}
      />
    </>
  )
}

export default Page
