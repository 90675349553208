/** @jsxImportSource @emotion/react */
import { Global, css } from '@emotion/react';

// mobile first
// breakpoints: 40em (640px), 48em (768px), 75em (1200px), 95em (1520px)

const GlobalStyles = () => (
  <Global
    styles={css`
      blockquote,
      dd,
      dl,
      figure,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      hr,
      p,
      pre {
        margin: 0;
      }
      html {
        scroll-behavior: smooth;
      }
      body {
        font-family: 'Inter', sans-serif;
        color: var(--theme-ui-colors-pl-dark, #050A30);
      }
      ul {
        list-style: disc;
        list-style-position: inside;
      }
      h1 {
        font-size: 36px;
        font-weight: 500;
      }
      h2 {
        font-size: 32px;
        font-weight: 400;
      }
      h3 {
        font-size: 28px;
        font-weight: 500;
      }
      h4 {
        font-size: 24px;
        font-weight: 500;
      }
      h6 {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 2.8px;
        line-height: 28px;
        text-transform: uppercase;
      }
      .header.fixed-top {
        width: 100%;
        background: var(--theme-ui-colors-pl-gray-white, #ffffff);
        position: fixed;
        z-index: 40;
      }
      #main-content-wrapper {
        min-height: 100vh;
      }
      .subheadline {
        font-size: 18px;
      }
      .pl-blue-gradient-text {
        background-image: linear-gradient(
          49deg,
          var(--theme-ui-colors-pl-blue-highlight, #0a94ef) 0%,
          #e1f0fa 100%
        );
        background-clip: text;
        -webkit-text-fill-color: transparent;
        color: var(--theme-ui-colors-pl-blue-highlight, #0a94ef);
      }
      .sm-button {
        min-width: 64px;
        height: 2rem;
        padding: 0 12px;
        font-size: 12px;
      }
      .md-button {
        min-width: 84px;
        height: 2.5rem;
        padding: 0 16px;
        font-size: 14px;
      }
      .lg-button {
        min-width: 96px;
        height: 3rem;
        padding: 0 18px;
        font-size: 16px;
      }
      .xl-button {
        min-width: 120px;
        height: 3.625rem;
        padding: 10px 24px;
        font-size: 18px;
      }
      .full-button {
        width: 100%;
        min-width: 120px;
        height: 3.625rem;
        padding: 10px 24px;
        font-size: 18px;
      }
      .nav-link {
        color: var(--theme-ui-colors-pl-blue-gray, #767676);
      }
      .nav-link-active {
        color: var(--theme-ui-colors-pl-dark, #767676);
        position: relative;
        &:after {
          content: '';
          position: absolute;
          bottom: 4px;
          left: 0;
          right: 0;
          height: 3px;
          border-radius: 2px;
          background: var(--theme-ui-colors-pl-main-brand, #4494d0);
        }
      }
      .card {
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        background-color: var(--theme-ui-colors-pl-gray-white, #ffffff);
        padding: 20px;
        border-radius: 8px;
      }
      .section {
        background-color: var(--theme-ui-colors-pl-gray-white, #ffffff);
        border-radius: 8px;
        padding: 20px;
      }
      .outer-section {
        background-color: var(--theme-ui-colors-pl-gray-background, #f9f9f9);
        padding: 0px;
      }
      @media (min-width: 40em) {
        .outer-section {
          padding: 40px;
          border-radius: 44px;
        }
        .section {
          padding: 40px;
          border-radius: 24px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        }
        .card {
          padding: 40px;
          border-radius: 24px;
        }
        h1 {
          font-size: 40px;
        }
        h2 {
          font-size: 38px;
        }
        h3 {
          font-size: 36px;
        }
        h4 {
          font-size: 28px;
        }
        .subheadline {
          font-size: 20px;
        }
      }
      @media (min-width: 48em) {
        h1 {
          font-size: 52px;
        }
      }
      @media (min-width: 75em) {
        h1 {
          font-size: 64px;
        }
        h2 {
          font-size: 48px;
        }
        h3 {
          font-size: 32px;
        }
      }
    `}
  />
);

export default GlobalStyles;
